<template>
<!--  v-show="!isSkeletonLoading.commentary" -->
 <div>
    <!-- AS-712 : && !isShowLiveTrackerUrl -->
   <div class="no-data w-100" v-show="!isShowCommentary">{{$t('NO_FEED')}}</div>

    <div class="display-flex match-detail-tracker__feed-commentary__wrapper" >
        <!-- <div class="mb-1rem match-detail__tracker-video" v-if="isShowLiveTrackerUrl">
            <div class="match-detail__tracker-header">
                {{$t('TRACKER')}}
            </div>
            <iframe  width="100%"  frameBorder="0" class="iframe" :src="matchDetailLiveTrackerUrl"></iframe>
        </div> -->
        <div class="match-detail__tracker-commentary" v-if="isShowCommentary">
            <div class="match-detail__tracker-header">
                {{$t('FEED')}}
            </div>
            <perfect-scrollbar v-if="!isMobile">
                <div class="match-detail__tracker-body">
                    <div class="match-detail__tracker-commentary__card" :class="{ 'text-important': o.important ,'text-goal': o.isgoal }" v-for="(o, index) in matchDetailCommentary" :key="index">
                        <div class="match-detail__tracker-commentary-left">{{o.minute}}'</div>
                        <div>{{o.content}}</div>
                    </div>
                </div>
            </perfect-scrollbar>
            <div v-else>
                <div class="match-detail__tracker-body">
                    <div class="match-detail__tracker-commentary__card" :class="{ 'text-important': o.important ,'text-goal': o.isgoal }" v-for="(o, index) in matchDetailCommentary" :key="index">
                        <div class="match-detail__tracker-commentary-left">{{o.minute}}'</div>
                        <div>{{o.content}}</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

    <!-- <GeneralContentSkeleton v-show="isSkeletonLoading.commentary"></GeneralContentSkeleton> -->
</template>

<script>
import {mapGetters,mapMutations,mapActions} from 'vuex'
import GeneralContentSkeleton from '@/components/skeletonLoading/GeneralContentSkeleton.vue';

export default {
    components: {
        GeneralContentSkeleton,
    },
    watch: {
        manualRefreshMatchDetail: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal) this.getMatchDetailCommentaryDataInterval();
            }
        },
    },
    data() {
        return {
            isShowLiveTrackerUrl:false,
            isShowCommentary:false,
            isShowNoData:false,
            matchDetailLiveTrackerUrl:'',
            matchDetailCommentary:[],
            matchId:'',
        
            intervalCommentary: null,
            isSkeletonLoading: {
                // tracker: false,
                commentary: false,
            },
        }
    },
    created() {
          this.matchId = this.$route.params.matchId;
    },
      beforeUnmount() {
        clearInterval(this.intervalCommentary)
     },
    computed: {
        ...mapGetters([
            'isStopTimmer',
            'isMobile',
            'manualRefreshMatchDetail',
        ]),
    },
    mounted() {
        // this.getMatchDetailLiveTrackerUrlData();
        this.getMatchDetailCommentaryDataInterval();
    },
    methods: {
        ...mapMutations([
            "MANUAL_REFRESH_MATCH_DETAIL",
        ]),
        ...mapActions([
            "getMatchDetailLiveTrackerUrl",
            "getMatchDetailCommentary"
        ]),
     
        // async getMatchDetailLiveTrackerUrlData(){
        //     if (this.manualRefreshMatchDetail) {
        //         this.isSkeletonLoading.tracker = true;
        //     }
        //     const result = await this.getMatchDetailLiveTrackerUrl({"matchId" : this.matchId})
        //     if (this.manualRefreshMatchDetail) {
        //         this.isSkeletonLoading.tracker = false;
        //         this.verifyIfAllAPICallsDone();
        //     }
        //     //result.result.url ="https://www.g-video.net/4002886.mp4"
            
        //     if (result.result === null){
        //         this.isShowLiveTrackerUrl = false;
        //         this.isShowNoData= true;
        //     } else {
        //         this.isShowNoData= false;
        //         this.isShowLiveTrackerUrl = true;
        //         this.matchDetailLiveTrackerUrl = result.result.url;  
        //     }
        // },  

        async getMatchDetailCommentaryDataInterval(){

            this.getMatchDetailCommentaryData();

            this.intervalCommentary = setInterval(() => {
                    if (this.isStopTimmer) {
                        clearInterval(this.intervalCommentary)
                    } else {
                        this.getMatchDetailCommentaryData();
                    }
                 
            }, 2000);
        },
         async getMatchDetailCommentaryData(){
            if (this.manualRefreshMatchDetail) {
                this.isSkeletonLoading.commentary = true;
            }
            const result = await this.getMatchDetailCommentary({"matchId" : this.matchId})
            if (this.manualRefreshMatchDetail) {
                this.isSkeletonLoading.commentary = false;
                this.verifyIfAllAPICallsDone();
            }

            if (result.result.length === 0) {
                this.isShowCommentary = false;
                this.isShowNoData= true;
                if (this.isStopTimmer) {
                    clearInterval(this.intervalCommentary)
                }
            } else {
                this.isShowNoData= false;
                this.isShowCommentary = true
                this.matchDetailCommentary = result.result;    
            }
        },
        // !this.isSkeletonLoading.tracker && 
        verifyIfAllAPICallsDone() {
            if (!this.isSkeletonLoading.commentary) {
                this.MANUAL_REFRESH_MATCH_DETAIL();
            }
        }
    },
}
</script>

<style>
.match-detail-tracker__feed-commentary__wrapper{
        flex-direction: column;
    }
/* .match-detail__tracker-video{
    width: 100%;
    height: 50vw
} */
.match-detail__tracker-header{
    padding: 0 1rem 1rem 1rem;
    /* margin: 0 1rem; */
    text-align: left;
    color: var(--color-grey-light);
    font-size: 0.8rem;
}
/* .match-detail__tracker-video .iframe{
        height:100%
    } */
.match-detail__tracker-body{
    margin: 0 1rem;
}
.match-detail__tracker-commentary__card{
    display: flex;
    border-bottom:1px solid var(--color-grey-20);
    color:var(--color-grey-light);
    padding: .5rem 0;
    text-align: left;
    font-size:.7rem 
}
.match-detail__tracker-commentary__card.text-important{
    color:#ED9C5A
}
/* priority is goal */
.match-detail__tracker-commentary__card.text-goal{ 
    color:#5CD66C
}

.match-detail__tracker-commentary-left{
    min-width: 2.5rem;
}
@media (min-width: 1200px){
    /* .match-detail__tracker-video{ */
        /* width: 70%; */
        /* flex:3;
        height:100%;
        margin-right:5rem
    } */
    
    .match-detail__tracker-commentary{
        /* width: 30%; */
        flex:1.5
    }
    .match-detail-tracker__feed-commentary__wrapper{
        flex-direction: row;
        height:37rem
    }
    .match-detail__tracker-body{
        height:33rem
    }
}
</style>